import React from "react"

import Page from "../templates/Page";

export default ({ location }) => (
  <Page title="Conditions générales d'utilisation" location={location} forceMap={false}>
    <h1>CONDITIONS GENERALES D&rsquo;UTILISATION (CGU)</h1>
    <p align="center">&nbsp;</p>
    <p align="left"><strong>OBJET</strong></p>
    <p>Les conditions g&eacute;n&eacute;rales d&rsquo;utilisation ont pour objet de d&eacute;finir les modalit&eacute;s et conditions dans lesquelles la soci&eacute;t&eacute; Mediareseau SARL, en qualit&eacute; d&rsquo;&eacute;diteur, met &agrave; la disposition des utilisateurs, le site internet &laquo;&nbsp;PLAN.NC&nbsp;&raquo;, les services disponibles en ligne ainsi que la mani&egrave;re dont l&rsquo;utilisateur acc&egrave;de au site et utilise ses services.</p>
    <p>&laquo;&nbsp;PLAN.NC&nbsp;&raquo; est un site destin&eacute; notamment &agrave; aider les particuliers et les professionnels amen&eacute;s &agrave; se rep&eacute;rer dans diff&eacute;rents lieux en Nouvelle-Cal&eacute;donie pour des raisons touristiques ou professionnelles. Les utilisateurs peuvent rechercher des lieux gr&acirc;ce aux plans des villes, cartes g&eacute;ographiques (rues, quartiers, villes, baies) et toutes les entreprises r&eacute;f&eacute;renc&eacute;es sur le site, gr&acirc;ce &agrave; l&rsquo;annuaire en ligne. Les professionnels b&eacute;n&eacute;ficient d&rsquo;un espace r&eacute;serv&eacute; (espace professionnel) d&eacute;nomm&eacute; &laquo;&nbsp;Vitrines Web&nbsp;&raquo;.</p>
    <p>Toute connexion au site est subordonn&eacute;e au respect des pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation. Le simple acc&egrave;s par l&rsquo;utilisateur au site <a href="http://www.plan.nc">www.plan.nc</a> implique l&rsquo;acceptation de l&rsquo;ensemble des conditions pos&eacute;es par l&rsquo;&eacute;diteur ci-apr&egrave;s&nbsp;:</p>
    <p>&nbsp;</p>
    <p><strong>Acc&egrave;s au site</strong></p>
    <p>L&rsquo;acc&egrave;s au site est disponible en continu (24 heures sur 24, 7 jours sur 7) sauf en cas de force majeure ou d&rsquo;&eacute;v&egrave;nements ind&eacute;pendants de la volont&eacute; de l&rsquo;&eacute;diteur et sur lesquels celui-ci n&rsquo;a aucun contr&ocirc;le (exemple&nbsp;: cyclone) et sous r&eacute;serve des &eacute;ventuelles pannes, coupures et interventions de maintenance indispensables au bon fonctionnement du site et des services.</p>
    <p>L&rsquo;&eacute;diteur ne peut en cons&eacute;quence assurer une disponibilit&eacute; du site et/ou de ses services ainsi que la fiabilit&eacute; des transmissions et des performances en termes de temps de r&eacute;ponse ou de qualit&eacute; de la r&eacute;ponse.</p>
    <p>Aucune assistance technique vis-&agrave;-vis de l&rsquo;utilisateur par des moyens &eacute;lectroniques ou t&eacute;l&eacute;phoniques n&rsquo;est pr&eacute;vue.</p>
    <p>L&rsquo;&eacute;diteur peut &ecirc;tre amen&eacute; &agrave; interrompre momentan&eacute;ment ou d&eacute;finitivement tout ou partie de ses services, &agrave; tout moment sans pr&eacute;avis. Dans ce cas, l&rsquo;utilisateur accepte que l&rsquo;&eacute;diteur ne soit pas responsable des interruptions et des cons&eacute;quences qui peuvent en d&eacute;couler pour l&rsquo;utilisateur ou tout tiers.</p>
    <p>&nbsp;</p>
    <p>Cr&eacute;ation d&rsquo;un compte sur le site plan.nc&nbsp;:</p>
    <p>L&rsquo;utilisateur a la possibilit&eacute; de cr&eacute;er un compte en ligne. Il renseigne les champs demand&eacute;s et choisit ses identifiants de connexion au site plan.nc.</p>
    <p>L&rsquo;utilisateur est en cons&eacute;quence seul responsable des &eacute;l&eacute;ments enregistr&eacute;s dans les champs renseign&eacute;s (donn&eacute;es mentionn&eacute;es sur la fiche client) et en particulier de ses identifiants de connexion&nbsp;: mot de passe et adresse &eacute;lectronique. Il doit veiller &agrave; conserver la confidentialit&eacute; la plus absolue de ces &eacute;l&eacute;ments et signaler imm&eacute;diatement &agrave; l&rsquo;&eacute;diteur toute utilisation qui en serait faite sans autorisation. Ces obligations de confidentialit&eacute; et de vigilance doivent &ecirc;tre strictement respect&eacute;es, afin de permettre &agrave; l&rsquo;&eacute;diteur d&rsquo;assurer la s&eacute;curit&eacute; du site internet contre des intrusions malveillantes susceptibles de mettre en danger le bon fonctionnement du site internet. Par cons&eacute;quent, l&rsquo;&eacute;diteur est en droit de suspendre l&rsquo;acc&egrave;s au site plan.nc, s&rsquo;il a des raisons de penser que la s&eacute;curit&eacute; des identifiants de connexion n&rsquo;est plus assur&eacute;e.</p>
    <p>De mani&egrave;re g&eacute;n&eacute;rale, l&rsquo;&eacute;diteur peut limiter l&rsquo;utilisation du service propos&eacute; lorsque cela s&rsquo;av&egrave;re justifi&eacute; particuli&egrave;rement au regard de la bonne marche du site.</p>
    <p>&nbsp;</p>
    <p><strong>Propri&eacute;t&eacute; intellectuelle</strong></p>
    <p>Les textes, graphiques, images, sons et vid&eacute;os composant la structure g&eacute;n&eacute;rale du site plan.nc sont et demeurent la propri&eacute;t&eacute; de l&#39;&eacute;diteur, de ses clients ou encore de ses partenaires. Toute repr&eacute;sentation, reproduction, exploitation partielle ou totale des contenus et services propos&eacute;s par le site plan.nc, par tout proc&eacute;d&eacute;, sans autorisation expresse et pr&eacute;alable de l&rsquo;&eacute;diteur, de ses clients ou de ses partenaires est strictement interdite et susceptible de constituer une contrefa&ccedil;on au sens des articles L.335-2 et des articles L.342-1 relatif au procedure de bases de donn&eacute;es du Code de la propri&eacute;t&eacute; intellectuelle.</p>
    <p>&nbsp;</p>
    <p><strong>INSCRIPTION REFERENCEMENT-REPERTOIRE professionnel</strong></p>
    <p>L&#39;acc&egrave;s au service de g&eacute;o localisation et de r&eacute;f&eacute;rencement de <a href="http://plan.nc">plan.nc</a> est strictement r&eacute;serv&eacute; aux entreprises, soci&eacute;t&eacute;s, commerces, artisans, collectivit&eacute;s et autres administrations, pour un usage professionnel et/ou commercial. Le service de <a href="http://plan.nc">plan.nc</a> est constitu&eacute; d&rsquo;un annuaire des entreprises et des soci&eacute;t&eacute;s. L&rsquo;ensemble des entreprises r&eacute;f&eacute;renc&eacute;es est dit &laquo;&nbsp;P.O.I&nbsp;&raquo; Point d&rsquo;Int&eacute;r&ecirc;t. Plan.nc r&eacute;f&eacute;rence sur la base de donn&eacute;es de son annuaire notamment les entreprises, soci&eacute;t&eacute;s, commerces, et autres entit&eacute;s ayant une activit&eacute; commerciale ainsi que les associations, les administrations par un P.O.I gratuit. Ce P.O.I pr&eacute;cise le nom de la soci&eacute;t&eacute;, le type d&rsquo;activit&eacute; exerc&eacute;, l&rsquo;adresse, le t&eacute;l&eacute;phone et sa g&eacute;o localisation. Les autres informations sont soumises &agrave; des options d&rsquo;abonnement payantes, fournies et sous la responsabilit&eacute; de l&#39;abonn&eacute;.</p>
    <p>Lors de votre r&eacute;f&eacute;rencement gratuit et/ou optionnel, il est imp&eacute;ratif de communiquer &agrave; <a href="http://plan.nc">l&rsquo;&eacute;diteur</a>, les donn&eacute;es professionnelles exactes. En cas de modification de ces donn&eacute;es, l&rsquo;&eacute;diteur doit en &ecirc;tre inform&eacute; imm&eacute;diatement, par mail ou par t&eacute;l&eacute;phone, afin qu&rsquo;il soit en mesure de proc&eacute;der aux modifications dans les plus brefs d&eacute;lais, l&rsquo;int&eacute;r&ecirc;t &eacute;tant de d&eacute;livrer des informations authentiques. L&rsquo;&eacute;diteur ne peut &ecirc;tre tenu pour responsable de l&rsquo;inexactitude des informations relatives aux entreprises et autres entit&eacute;s qui seraient en ligne sur son site.</p>
    <p align="left">&nbsp;</p>
    <p align="left"><strong>Liens hypertextes</strong></p>
    <p>Le site plan.nc contient des liens hypertextes vers d&rsquo;autres sites pr&eacute;sents sur le r&eacute;seau Internet. Lorsque l&rsquo;utilisateur clique sur ces liens, il quitte alors le site plan.nc.</p>
    <p>La cr&eacute;ation d&rsquo;un lien vers la page de pr&eacute;sentation de ce site sans autorisation expresse de l&rsquo;&eacute;diteur est possible. L&rsquo;&eacute;diteur ne peut exiger aucune autorisation ou demande d&rsquo;information pr&eacute;alable provenant d&rsquo;un site qui souhaite &eacute;tablir un lien vers le site de l&rsquo;&eacute;diteur. Il est pr&eacute;f&eacute;rable n&eacute;anmoins d&rsquo;afficher ce site dans une nouvelle fen&ecirc;tre du navigateur. Cependant, en cas de non-conformit&eacute; ou d&rsquo;&eacute;l&eacute;ments apparaissant comme non conformes &agrave; l&rsquo;objet du site plan.nc, l&rsquo;&eacute;diteur se r&eacute;serve le droit de demander la suppression d&rsquo;un lien.</p>
    <p align="left">&nbsp;</p>
    <p align="left"><strong>Responsabilit&eacute; de l&rsquo;&eacute;diteur</strong></p>
    <p>Si les informations figurant sur le site plan.nc proviennent de sources consid&eacute;r&eacute;es comme fiables, celles-ci peuvent n&eacute;anmoins contenir des inexactitudes techniques et des erreurs typographiques.</p>
    <p>Il est recommand&eacute; de prendre ses informations avec la plus grande pr&eacute;caution. D&egrave;s que ces inexactitudes ou erreurs sont port&eacute;es &agrave; la connaissance de l&rsquo;&eacute;diteur, celui-ci effectue imm&eacute;diatement les corrections.</p>
    <p>Les informations accessibles sur ce site sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es &agrave; tout moment et font l&rsquo;objet de mises &agrave; jour quotidiennes. Il se peut que celles-ci soient effectu&eacute;es entre le moment de leur t&eacute;l&eacute;chargement et le moment o&ugrave; l&rsquo;utilisateur en prend connaissance.</p>
    <p>L&rsquo;utilisateur assume la pleine et enti&egrave;re responsabilit&eacute; de l&rsquo;usage et de l&rsquo;interpr&eacute;tation qu&rsquo;il fait des informations accessibles sur le site plan.nc ainsi que les cons&eacute;quences qui en d&eacute;couleraient. Par cons&eacute;quent, il ne peut rechercher la responsabilit&eacute;, ni intenter aucun recours contre l&rsquo;&eacute;diteur pour ce motif.</p>
    <p>&nbsp;</p>
    <p><strong>REGLES DE BON USAGE DE L&rsquo;OUTIL INTERNET</strong></p>
    <p>L&rsquo;utilisateur d&eacute;clare accepter les caract&eacute;ristiques et les limites d&rsquo;Internet, et reconna&icirc;t notamment que :</p>
    <p>L&rsquo;&eacute;diteur n&rsquo;assume aucune responsabilit&eacute; sur les services accessibles par Internet et n&rsquo;exerce aucun contr&ocirc;le de quelque forme que ce soit sur la nature et les caract&eacute;ristiques des donn&eacute;es qui pourraient transiter par l&rsquo;interm&eacute;diaire de son centre d&rsquo;h&eacute;bergement.</p>
    <p>L&rsquo;utilisateur reconna&icirc;t que les donn&eacute;es circulant sur Internet ne sont pas prot&eacute;g&eacute;es notamment contre les &eacute;ventuels d&eacute;tournements. La pr&eacute;sence du logo du professionnel institue une pr&eacute;somption simple de validit&eacute;. La communication de toute information consid&eacute;r&eacute;e par l&rsquo;utilisateur comme sensible ou confidentielle se fait sous son enti&egrave;re responsabilit&eacute; et donc en toute connaissance de cause.</p>
    <p>L&rsquo;utilisateur reconna&icirc;t que les donn&eacute;es circulant sur Internet peuvent &ecirc;tre r&eacute;glement&eacute;es en termes d&rsquo;usage ou &ecirc;tre prot&eacute;g&eacute;es par des droits de propri&eacute;t&eacute; intellectuelle. En cas d&rsquo;atteinte &agrave; ces r&egrave;gles, il est seul responsable de l&rsquo;usage des donn&eacute;es qu&rsquo;il consulte, interroge et transf&egrave;re sur Internet et des cons&eacute;quences dommageables &eacute;ventuelles engendr&eacute;es.</p>
    <p>Il est inform&eacute; en outre que la communication &agrave; des tiers notamment de ses identifiants de connexion et de toute information qui lui est personnelle relevant de son propre fait reste sous son enti&egrave;re responsabilit&eacute;. Il lui appartient de prendre toutes les pr&eacute;cautions et mesures n&eacute;cessaires permettant une protection de ses donn&eacute;es contre la contamination de virus &eacute;ventuels pr&eacute;sents sur le r&eacute;seau internet.</p>
    <p>&nbsp;</p>
    <p><strong>PARTENAIRES DE L&rsquo;EDITEUR</strong></p>
    <p>L&rsquo;utilisateur peut &ecirc;tre en relation directe avec les partenaires de l&rsquo;&eacute;diteur, en raison d&rsquo;une accessibilit&eacute; directe sur le site. Dans ce cas, l&rsquo;&eacute;diteur ne peut voir sa responsabilit&eacute; engag&eacute;e pour un quelconque dommage qui r&eacute;sulterait de cette relation directe.</p>
    <p>&nbsp;</p>
    <p><strong>COOKIES</strong></p>
    <p>L&rsquo;utilisateur est averti que via son navigateur, il a la possibilit&eacute; de choisir et de d&eacute;terminer les r&egrave;gles et modalit&eacute;s relatives &agrave; l&rsquo;acceptation ou non de cookies. Il lui est fortement conseill&eacute; de d&eacute;finir les param&eacute;trages adapt&eacute;s en fonction de ses besoins et de consulter pour cela l&rsquo;aide propos&eacute;e par son navigateur.</p>
    <p>&nbsp;</p>
    <p>Dans le cas d&rsquo;un refus de r&eacute;ception des cookies, l&rsquo;&eacute;diteur informe que certaines caract&eacute;ristiques personnalis&eacute;es du site web ne pourront pas &ecirc;tre fournies. Ainsi, l&rsquo;utilisateur ne sera pas en mesure de profiter pleinement de toutes les fonctionnalit&eacute;s du site web.</p>
    <p>&nbsp;</p>
    <p><strong>U</strong><strong>TILITE </strong><strong>ET UTILISATION DES COOKIES PAR L&rsquo;EDITEUR</strong></p>
    <p>Les informations fournies par les cookies peuvent aider l&rsquo;&eacute;diteur &agrave; analyser la navigation des visiteurs/utilisateurs et ainsi permettre une am&eacute;lioration de l&rsquo;information.</p>
    <p>Ainsi pour le fonctionnement du site web, les cookies offrent des fonctionnalit&eacute;s telles que le souvenir des connexions de l&rsquo;utilisateur entre les sessions, la mise en page du site effectu&eacute;e lorsque la possibilit&eacute; est pr&eacute;sente, etc. Deux types de cookies sont utilis&eacute;s : les cookies session et les cookies persistants.</p>
    <p>Les cookies ont pour finalit&eacute; exclusive de permettre ou faciliter la communication par voie &eacute;lectronique et sont strictement n&eacute;cessaires &agrave; la fourniture des services de communication en ligne de l&rsquo;&eacute;diteur, &agrave; la demande expresse de l&#39;utilisateur.</p>
    <p>&nbsp;</p>
    <p><strong>Modification des conditions d&rsquo;utilisation</strong></p>
    <p>L&rsquo;&eacute;diteur se r&eacute;serve la possibilit&eacute; de modifier, &agrave; tout moment et sans pr&eacute;avis, les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation afin de les adapter aux &eacute;volutions du site et/ou de son exploitation. Dans ce cas, l&rsquo;utilisateur en sera averti par une mention expresse sur le site plan.nc ou par e-mail. Il devra alors accepter les nouvelles conditions g&eacute;n&eacute;rales d&rsquo;utilisation (utilisation du site vaut acceptation) et se conformer aux nouvelles r&egrave;gles d&rsquo;utilisation du site plan.nc.</p>
    <p>&nbsp;</p>
    <p><strong>OPPOSABILITE DES CONDITIONS GENERALES D&rsquo;UTILISATION</strong></p>
    <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation sont accessibles directement depuis la page d&rsquo;accueil du site plan.nc. Il en est de m&ecirc;me pour les mentions l&eacute;gales du site. Par cons&eacute;quent, l&rsquo;utilisateur est averti qu&rsquo;en cas de litige, les tribunaux reconnaissent l&rsquo;opposabilit&eacute; des conditions g&eacute;n&eacute;rales d&rsquo;utilisation du site et de ses mentions l&eacute;gales &agrave; tout utilisateur qui ne les auraient pas respect&eacute; et ce m&ecirc;me en l&rsquo;absence d&rsquo;une acceptation expresse, &agrave; la condition que celles-ci soient suffisamment visibles.</p>
    <p>En l&rsquo;occurrence, la page du site relative aux conditions g&eacute;n&eacute;rales d&rsquo;utilisation est visible et accessible depuis la page d&rsquo;accueil, l&rsquo;utilisateur est donc r&eacute;put&eacute; avoir pris pleinement connaissance des conditions d&rsquo;utilisation ainsi que des mentions l&eacute;gales du site.</p>
    <p>&nbsp;</p>
    <p><strong>competence de la juridiction et droit applicable EN CAS DE LITIGE</strong></p>
    <p>Tant le pr&eacute;sent site que les modalit&eacute;s et conditions de son utilisation sont r&eacute;gis par le code de la propri&eacute;t&eacute; intellectuelle et l&rsquo;ensemble des dispositions l&eacute;gislatives et r&egrave;glementaires applicables en Nouvelle-Cal&eacute;donie, quel que soit le lieu d&rsquo;utilisation. Avant toute contestation devant la juridiction comp&eacute;tente, un r&egrave;glement amiable devra &ecirc;tre engag&eacute; par la partie la plus diligente. Ce n&rsquo;est qu&rsquo;en cas d&rsquo;&eacute;chec du r&egrave;glement amiable des conflits que les tribunaux seront saisis et seuls comp&eacute;tents pour conna&icirc;tre du litige.</p>

  </Page>
)
